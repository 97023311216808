import { createSlice } from "@reduxjs/toolkit"
import { pasteCopy } from "./workspaceNoteSlice"

export const copyNoteSlice = createSlice({
	name: "copyNote",
	initialState: { value: {id: -1} },
	reducers: {
		initializeCopyNote: (state, action) => {
			state.value = action.payload
		},
		cancelCopyNote: (state) => {
			state.value = {id: -1}
		},
		completeCopyNote: (state) => {
			state.value = {id: -1}
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(pasteCopy.fulfilled, (state) => {
				state.value = {id: -1}
			})
			.addCase(pasteCopy.rejected, (state) => {
				state.value = {id: -1}
			})
	}
})

export const { initializeCopyNote, cancelCopyNote, completeCopyNote } = copyNoteSlice.actions
export const selectCopyNote = (state) => state.copyNote
export default copyNoteSlice.reducer