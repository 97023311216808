import { useEffect, useState } from "react"
//import axios from "axios"
import PanelLoading from "../PanelLoading"
import PanelInfiniteScroll from "./PanelInfiniteScroll"
import ImportedArticle from "../articles/ImportedArticle"
import { Button, Box } from "@mui/material"
import { Inventory, Visibility } from "@mui/icons-material"
import { useDispatch, useSelector } from "react-redux"
import {
	selectToolPanelView,
	setToolPanelView,
} from "../../reducers/toolPanelViewSlice"
import {
	selectHasMoreUnassignedArticles,
	selectUnassignedArticles,
	incrementPageAndFetchArticles,
	selectCurrentPage,
} from "../../reducers/unassignedArticlesEventsSlice"
import { selectRecentlyViewedArticles, clearArticles, updateArticle } from "../../reducers/recentlyReviewedSlice"
import RawArticle from "../articles/RawArticle"
import { selectWorkspaceId, selectWorkspaceExclusions } from "../../reducers/workspaceSlice"
import { checkExclusion } from "../../utils/articleUtilities"
export const TOOL_PANEL_VIEW = {
	RECENTLY_VIEWED: "RECENTLY_VIEWED",
	UNASSIGNED: "UNASSIGNED",
}

export function ToolPanelNav() {
	const dispatch = useDispatch()
	const view = useSelector(selectToolPanelView)

	const clearRecentlyViewedArticles = () => {
		dispatch(clearArticles())
	}

	return (
		<Box sx={{ display: "flex", justifyContent: "space-between" }}>
			<Box>
				<Button
					onClick={() =>
						dispatch(setToolPanelView(TOOL_PANEL_VIEW.RECENTLY_VIEWED))
					}
					variant={
						view === TOOL_PANEL_VIEW.RECENTLY_VIEWED ? "contained" : "outlined"
					}
					sx={{ marginRight: "20px" }}
				>
					<Visibility sx={{ marginRight: "10px" }} /> Recently Viewed
				</Button>
				<Button
					onClick={() => dispatch(setToolPanelView(TOOL_PANEL_VIEW.UNASSIGNED))}
					variant={view === TOOL_PANEL_VIEW.UNASSIGNED ? "contained" : "outlined"}
				>
					<Inventory sx={{ marginRight: "10px" }} /> Unassigned
				</Button>
			</Box>
			{
				TOOL_PANEL_VIEW.RECENTLY_VIEWED ==  view && (
					<Button
						onClick={clearRecentlyViewedArticles}
						variant={"outlined"}
						color={"error"}
					>
						Clear Recently Viewed
					</Button>
				)
			}
		</Box>
	)
}

export function ToolPanel({ workspace }) {
	const dispatch = useDispatch()
	const view = useSelector(selectToolPanelView)
	const unassignedList = useSelector(selectUnassignedArticles)

	const hasMore = useSelector(selectHasMoreUnassignedArticles)
	const currentPage = useSelector(selectCurrentPage)
	const recentlyReviewedArticles = useSelector(selectRecentlyViewedArticles)
	const [isLoading] = useState(false)
	const [targetArticle, setTargetArticle] = useState(null)
	const workspaceId = useSelector(selectWorkspaceId)
	const workspaceExcludedArticles = useSelector(selectWorkspaceExclusions)
	const setArticleAsImported = (article) => dispatch(updateArticle(article))
	
	// switching workspace reset
	useEffect(() => {
		dispatch(setToolPanelView(null))
	}, [workspace])

	
	const paginate = () => {

		if (hasMore) {
			dispatch(incrementPageAndFetchArticles({
				page: currentPage + 1, workspaceId: workspaceId
			}))
		}
	}

	const dataLength = view === TOOL_PANEL_VIEW.RECENTLY_VIEWED ? recentlyReviewedArticles.length :  unassignedList.length

	return (
		<>
			{
				isLoading ? (
					<PanelLoading />
				) : (
					<PanelInfiniteScroll
						dataLength={dataLength}
						hasMore={hasMore}
						loader={<PanelLoading />}
						next={paginate}
					>
						{
							view === TOOL_PANEL_VIEW.UNASSIGNED && unassignedList.map((result, index) => (
								<ImportedArticle
									key={result.articleId}
									articleWorkspace={result}
									setTargetArticle={setTargetArticle}
									targetArticle={targetArticle}
									isUnassigned={true}
									index={index}
								/>
							))
						}
						{
							view === TOOL_PANEL_VIEW.RECENTLY_VIEWED && recentlyReviewedArticles.map((result, index) => {
								const isDeleted = !!result.deletedAt
								if (result.articleId && !isDeleted) {
									return (
										<ImportedArticle
											key={result.articleId}
											articleWorkspace={result}
											setTargetArticle={setTargetArticle}
											targetArticle={targetArticle}
											isUnassigned={ unassignedList.find(unassignedArticle => unassignedArticle.articleId === result.articleId) }
											index={index}
										/>
									)
								} else {
									const rawArticleObject = isDeleted ? result.article : result
									return(
										
										<RawArticle
											key={rawArticleObject.PMID || rawArticleObject.DOI || rawArticleObject.ISBN || rawArticleObject.ARXIVID}
											workspaceId={workspaceId}
											article={rawArticleObject}
											targetArticle={targetArticle}
											setTargetArticle={setTargetArticle}
											setArticleAsImported={setArticleAsImported}
											isExcluded={checkExclusion(rawArticleObject.PMID, workspaceExcludedArticles)}
											index={index}
											deletedAt={isDeleted}
										/>
									)
								}

							})
						}
					</PanelInfiniteScroll>
				)}
		</>
	)
}
