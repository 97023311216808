import {createAsyncThunk, createSlice, createSelector} from "@reduxjs/toolkit"
import { toolPanelViewSlice } from "./toolPanelViewSlice"
import axios from "axios"
import {DYNAMIC_PANEL_TAB_INDEX_MAP} from "./dynamicViewSlice"
import {TOOL_PANEL_VIEW} from "../components/dynamic/ToolPanel"
/**
 * A map of articles, where the key is either the id or workspace.articleId.
 * There should never be duplicate articles selected unless PubMed screwed up.
 * So using a map makes retrieval, sorting, and deletion easier.
 * */
const initialValue = {}

export const selectAllArticles = createAsyncThunk(
	"selectedArticles/selectAll",
	async (_, {rejectWithValue, getState}) => {
		const state = getState()
		const { 
			dynamicView,
			toolPanelView, 
			workspace,
			noteArticleTabsV2,
			notificationPanelView,
		} = state
		const workspaceId = workspace.value.id


		if(dynamicView.value === DYNAMIC_PANEL_TAB_INDEX_MAP.TOOLS && toolPanelView.value === TOOL_PANEL_VIEW.UNASSIGNED) {
			const {data} = await axios.get(`/api/workspace/${workspaceId}/article/unassigned`)
			return data.map((articleWorkspace) => articleWorkspace.article)
		} else if (dynamicView.value === DYNAMIC_PANEL_TAB_INDEX_MAP.NOTE_ARTICLE_LIST) {
			const currentNoteId = noteArticleTabsV2.currentIndex
			const articles = noteArticleTabsV2.tabs[currentNoteId].articles
			return articles.map((articleWorkspace) => articleWorkspace.article)
		} else if (dynamicView.value === DYNAMIC_PANEL_TAB_INDEX_MAP.NOTIFICATION) {
			return notificationPanelView.articles.map(articleWorkspace => articleWorkspace.article)
		} else {
			return rejectWithValue("sad trombone")
		}
	}
)

export const selectedArticlesSlice = createSlice({
	name: "selectedArticles",
	initialState: {
		value: initialValue,
	},
	reducers: {
		//TODO: think about refactoring or renaming this, since it does more than just "adding" a selected article
		addSelectedArticle: (state, action) => {
			const id =
				action.payload.articleId ||
				action.payload.id ||
				action.payload.DOI ||
				action.payload.PMID ||
				action.payload.PMCID

			if (state.value[id] === undefined) {
				state.value[id] = action.payload
			} else {
				delete state.value[id]
			}
		},
		addManySelectedArticles: (state, action) => {
			for (let article of action.payload) {
				const id =
					article.articleId ||
					article.id ||
					article.DOI ||
					article.PMID ||
					article.PMCID
				if (state.value[id] === undefined) {
					state.value[id] = article
				}
			}
		},
		clearSelectedArticles: (state) => {
			state.value = initialValue
		},
	},
	extraReducers: (builder) => {
		builder.addCase (
			toolPanelViewSlice.actions.setToolPanelView,
			selectedArticlesSlice.caseReducers.clearSelectedArticles
		)
		builder.addCase(
			selectAllArticles.fulfilled,
			selectedArticlesSlice.caseReducers.addManySelectedArticles
		)
		builder.addCase(
			"noteArticleTabsV2/setCurrentTabIndex",
			selectedArticlesSlice.caseReducers.clearSelectedArticles
		)
	}
})

export const {
	addSelectedArticle,
	clearSelectedArticles,
} = selectedArticlesSlice.actions

export const selectSelectedArticles = (state) => state.selectedArticles.value
export const selectIsSelectingArticles = createSelector(
	[(state) => state.selectedArticles.value],
	(selectedArticles) => Object.keys(selectedArticles).length >= 1
)

export const selectNumberOfSelectedArticles = createSelector(
	[(state) => state.selectedArticles.value],
	(selectedArticles) => {
		const numberOfSelected = Object.keys(selectedArticles).length
		return numberOfSelected > 0 ? numberOfSelected : "-"
	}
)

export default selectedArticlesSlice.reducer
