import { createSlice } from "@reduxjs/toolkit"
import { cancelMovingNote, completeMovingNote } from "./moveNoteSlice"
import { moveNote, openToPath, pasteCopy } from "./workspaceNoteSlice"
import { addNote } from "./workspaceNoteSlice"
import { attachArticlesToNote } from "./breadcrumbSlice"

const initialValue = { id: null, path: [] }

export const targetNoteSlice = createSlice({
	name: "targetNote",
	initialState: {
		value: initialValue,
	},
	reducers: {
		setTargetNote: (state, action) => {
			state.value = action.payload
		},
		clearTargetNote: (state) => {
			state.value = initialValue
		},
	},
	extraReducers: (builder) => {
		builder.addCase(pasteCopy.fulfilled, (state, action) => {
			const { note } = action.payload
			if ( note ) {
				state.value = note
			}
		})
		builder.addCase(openToPath.fulfilled, (state, action) => {
			const { note, fromWebsocket } = action.payload
			if (!fromWebsocket) {
				state.value = note
			}
		})
		builder.addCase(attachArticlesToNote.fulfilled, (state, action) => {
			const {note} = action.payload
			state.value = note
		})
		builder.addCase(addNote.fulfilled, (state, action) => {
			const {newNote} = action.payload
			state.value = newNote
		})
		builder.addCase(cancelMovingNote, (state, action) => {
			state.value = action.payload
		}),
		builder.addCase(completeMovingNote, (state, action) => {
			state.value = action.payload
		})
		builder.addCase(moveNote.fulfilled, (state, action) => {
			const {newNote} = action.payload
			state.value = newNote
		})
	},
})

export const { setTargetNote, clearTargetNote } = targetNoteSlice.actions

export const selectTargetNote = (state) => state.targetNote.value

export default targetNoteSlice.reducer
