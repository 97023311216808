import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import PanelLoading from "../../PanelLoading"
import PanelInfiniteScroll from "../PanelInfiniteScroll"
import ImportedArticle from "../../articles/ImportedArticle"
import RawArticle from "../../articles/RawArticle"
import EmptyResults from "../EmptyResults"
import { selectArticleSearchQuery, setResultsCount } from "../../../reducers/articleSearchQuery"
import axios from "axios"
import { useSnackbar } from "notistack"
import { selectWorkspaceExclusions } from "../../../reducers/workspaceSlice"
import { updateArticleAsImported } from "../../../utils/articleUtilities"
import { checkExclusion } from "../../../utils/articleUtilities"
import { selectAllUnassignedArticles } from "../../../reducers/unassignedArticlesEventsSlice"
import {
	setDeletedArticlesAsDeleted,
	setExcludedArticlesAsExcluded,
	setUnassignedArticlesAsImported
} from "../../../utils/automatedSearchUtilities"
import { selectWorkspaceDeletedArticles } from "../../../reducers/deletedArticlesSlice"

export function SearchPanel({ workspace, unassignedArticleIds }) {
	const { enqueueSnackbar } = useSnackbar(),
		searchParams = useSelector(selectArticleSearchQuery),
		workspaceExcludedArticles = useSelector(selectWorkspaceExclusions),
		unassignedArticles = useSelector(selectAllUnassignedArticles),
		deletedArticles = useSelector(selectWorkspaceDeletedArticles),
		dispatch = useDispatch()
	const [targetArticle, setTargetArticle] = useState(null),
		[search, setSearch] = useState({
			count: 0,
			articles: [],
			isLoading: false,
			isPaginating: false,
			page: 1,
		})

	useEffect(() => {
		const { query, source } = searchParams
		if (!query) {
			return
		}

		setSearch({ ...search, isLoading: true })
		axios
			.get(
				`/api/workspace/${workspace.id
				}/article?search=${query}&page=1&imported=1&pubmed=${source === "pubmed" ? 1 : 0
				}`
			)
			.then(({ data: results }) => {
				const { count, articles, page } = results

				if (count === -1) {
					setSearch({ ...search, isLoading: false })
					enqueueSnackbar(
						"This search returned no results, check your query and try again",
						{
							variant: "error",
							autoHideDuration: 3000,
							disableWindowBlurListener: true,
							preventDuplicate: true,
						}
					)
				} else {
					setSearch((prevState) => ({
						...prevState,
						count,
						articles,
						page,
						isLoading: false,
					}))
					dispatch(setResultsCount({
						total: count,
						pageCount: page,
					}))
				}
			})
	}, [searchParams.query, searchParams.source])

	useEffect(() => { }, [search.articles])

	useEffect(() => {
		// Needs to reset search if workspace is changed
		setSearch({
			count: 0,
			articles: new Array(0),
			isLoading: false,
			isPaginating: false,
			page: 1,
		})
	}, [workspace])

	const paginate = () => {
		const { page, isPaginating } = search
		const { query, source } = searchParams

		if (isPaginating) {
			return
		}

		setSearch((prevState) => ({ ...prevState, isPaginating: true }))
		axios
			.get(
				`/api/workspace/${workspace.id
				}/article?search=${query}&page=${page}&imported=1&pubmed=${source === "pubmed" ? 1 : 0
				}`
			)
			.then(({ data: results }) => {
				setSearch((prevState) => ({
					...prevState,
					articles: search.articles.concat(results.articles),
					page: page + 1,
					isPaginating: false,
				}))
				dispatch(setResultsCount({
					total: parseInt(results.count),
					currentPage: results.page,
				}))
			})
	}

	useEffect(() => {
		setExcludedArticlesAsExcluded(search, setSearch)
	}, [workspaceExcludedArticles])

	useEffect(() => {
		setUnassignedArticlesAsImported(unassignedArticles, setSearch)
	}, [unassignedArticles])

	useEffect(() => {
		setDeletedArticlesAsDeleted(deletedArticles, setSearch)
	}, [deletedArticles])

	const setArticleAsImported = updateArticleAsImported(setSearch)

	if (search.isLoading) {
		return <PanelLoading />
	}

	if (
		searchParams.query &&
		searchParams.source === "library" &&
		search.count === 0
	) {
		return (<EmptyResults title={"No Results Found"} body={"Currently, there are no search results in imported articles. Please try again in the future or try a new search."} />)
	}

	if (
		search.count == 0 &&
		!search.isLoading &&
		searchParams.query
	) { 
		return (<EmptyResults title={"No Results Found"} body={"Currently, there are no search results for your query. Please try again in the future or try a new search."} />)
	}

	return (
		<PanelInfiniteScroll
			next={paginate}
			dataLength={search.articles.length}
			hasMore={search.articles.length != search.count}
			loader={<PanelLoading />}
		>
			{search.articles.map((result, index) => {
				if (result.articleId && result.deletedAt === null) {
					return (
						<ImportedArticle
							key={result.articleId}
							articleWorkspace={result}
							targetArticle={targetArticle}
							setTargetArticle={setTargetArticle}
							isUnassigned={unassignedArticleIds.includes(result.articleId)}
							index={index}
						/>
					)
				} else {
					let rawArticle = result.articleId ? result.article : result
					return (
						<RawArticle
							key={rawArticle.DOI || rawArticle.PMID}
							workspaceId={workspace.id}
							article={rawArticle}
							targetArticle={targetArticle}
							setTargetArticle={setTargetArticle}
							setArticleAsImported={setArticleAsImported}
							isExcluded={checkExclusion(rawArticle.PMID, workspaceExcludedArticles)}
							index={index}
							deletedAt={result.deletedAt}
						/>
					)
				}
			})}
		</PanelInfiniteScroll>
	)
}
