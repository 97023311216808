// middleware.js
import { setWorkspace } from "../../reducers/workspaceSlice"
import { getAllUnassignedArticles, getUnassignedArticles } from "../../reducers/unassignedArticlesEventsSlice" 

const fetchUnassignedArticlesMiddleware = store => next => action => {
	if (action.type === setWorkspace.type) {
		const workspaceId = action.payload.id
		store.dispatch(getUnassignedArticles(workspaceId))
		store.dispatch(getAllUnassignedArticles(workspaceId))
	}
	return next(action)
}

export default fetchUnassignedArticlesMiddleware
