import {createAsyncThunk, createSlice, createSelector} from "@reduxjs/toolkit"
import axios from "axios"
import { workspaceSlice } from "./workspaceSlice"
import { deleteNote } from "./workspaceNoteSlice"

export const getUnassignedArticles = createAsyncThunk(
	"unassignedArticlesEventsSlice/getUnassignedArticles",
	async (workspaceId) => {
		const listOfUnassignedArticles = axios
			.get(`/api/workspace/${workspaceId}/article/unassigned`)
			.then(({ data }) => data)
			.catch(e => console.error("failed to get Unassigned Articles: ", e))
			
		return listOfUnassignedArticles
	}
)

export const getAllUnassignedArticles = createAsyncThunk(
	"unassignedArticlesEventsSlice/getAllUnassignedArticles",
	async (workspaceId) => {
		console.log("rb 759 fetch unassigned articles")
		const listOfUnassignedArticles = axios
			.get(`/api/workspace/${workspaceId}/article/unassigned/all`)
			.then(({ data }) => data)
			.catch(e => console.error("failed to get Unassigned Articles: ", e))
		return listOfUnassignedArticles
	}
)

export const incrementPageAndFetchArticles = createAsyncThunk(
	"unassignedArticlesEventsSlice/incrementPageAndFetchArticles",
	async ({ workspaceId, page }, {getState}) => {
		const state = getState()
		const fetchedCount = state.unassignedArticlesEvent.value.fetchedCount
		
		const listOfUnassignedArticles = axios
			.get(`/api/workspace/${workspaceId}/article/unassigned`, { params: { page, fetchedCount} })
			.then(({ data }) => data)
			.catch(e => console.error("failed to get Unassigned Articles: ", e))
		return listOfUnassignedArticles
	}
)


export const unassignedArticlesEventsSlice = createSlice({
	name: "unassignedArticlesEventsSlice",
	initialState: {
		value: {
			allUnassignedArticles: [],
			unassignedArticles: [],
			hasMore: true,
			currentPage: 1,
			fetched: 0,
		},
	},
	reducers: {
		clearUnassigned: (state) => {
			state.value.allUnassignedArticles = []
			state.value.unassignedArticles = []
			state.value.currentPage = 1
			state.value.hasMore = true
			state.value.fetchedCount = 0
		},
		concatUnassignedArticles: (state, action) => {
			state.value.unassignedArticles = state.value.unassignedArticles.concat(action.payload)
			state.value.allUnassignedArticles = state.value.allUnassignedArticles.concat(action.payload)
		},
		removeUnassignedArticles: (state, action) => {
			// remove from unassigned paged list
			const articleIdsToRemove = action.payload.map(article => article.articleId)
			state.value.unassignedArticles = state.value.unassignedArticles.filter(article => !articleIdsToRemove.includes(article.articleId))
			// remove from unassigned master list
			state.value.allUnassignedArticles = state.value.allUnassignedArticles.filter(article => !articleIdsToRemove.includes(article.articleId))
			state.value.fetchedCount -= articleIdsToRemove.length
		},
	},
	extraReducers: (builder) => {
		builder.addCase(deleteNote.fulfilled, (state, action) => {
			const { unassignedArticles } = action.payload
			state.value.unassignedArticles = state.value.unassignedArticles.concat(unassignedArticles)
			state.value.fetchedCount += unassignedArticles.length
		})
		builder.addCase(getUnassignedArticles.fulfilled, (state, action) => {
			state.value.unassignedArticles = action.payload
			state.value.currentPage = 1
			state.value.hasMore = action.payload.length > 0
			state.value.fetchedCount = action.payload.length
		})
		builder.addCase(incrementPageAndFetchArticles.fulfilled, (state, action) => {
			state.value.unassignedArticles = state.value.unassignedArticles.concat(action.payload)
			state.value.currentPage += 1
			state.value.hasMore = action.payload.length > 0
			state.value.fetchedCount += action.payload.length
		})
		builder.addCase(
			workspaceSlice.actions.setWorkspace,
			unassignedArticlesEventsSlice.caseReducers.clearUnassigned
		)
		builder.addCase(getAllUnassignedArticles.fulfilled, (state, action) => {

			state.value.allUnassignedArticles = action.payload
		})
	}
})

export const { concatUnassignedArticles, removeUnassignedArticles } = unassignedArticlesEventsSlice.actions

export const selectAllUnassignedArticles = (state) => state.unassignedArticlesEvent.value.allUnassignedArticles
export const selectUnassignedArticles = (state) => state.unassignedArticlesEvent.value.unassignedArticles

// Memoized selectors for article IDs
export const selectAllUnassignedArticleIds = createSelector(
	[selectAllUnassignedArticles],
	(allUnassignedArticles) => allUnassignedArticles.map(article => article.articleId)
)

export const selectUnassignedArticleIds = createSelector(
	[selectUnassignedArticles],
	(unassignedArticles) => unassignedArticles.map(article => article.articleId)
)

// Non-memoized selectors for pagination and other values
export const selectHasMoreUnassignedArticles = (state) => state.unassignedArticlesEvent.value.hasMore
export const selectCurrentPage = (state) => state.unassignedArticlesEvent.value.currentPage

export default unassignedArticlesEventsSlice.reducer
