import {Box, SvgIcon, Tooltip} from "@mui/material"
import { useEffect, useState } from "react"
import {TabPanel, NavPanel} from "../panels/TabPanel"
import {
	NavigationTabWrapper,
	NavigationTab,
	NavigationTabs,
	NavigationSecondaryFnWrapper,
	NavigationWrapper,
} from "../panels/Navigation"
import {
	Build,
	FileOpen,
	//FindReplace,
	Search,
	Notifications,
	CloudUpload
} from "@mui/icons-material"
import { SearchPanel } from "./SearchPanel/SearchPanel"
import { SearchPanelNav } from "./SearchPanel/SearchPanelNav"
import { NoteArticleListPanel } from "./NoteArticleList/NoteArticleListPanel"
import { NoteArticleListPanelNav } from "./NoteArticleList/NoteArticleListPanelNav"
import { useDispatch, useSelector } from "react-redux"
import { selectNoteArticleTabs } from "../../reducers/noteArticleTabsSlice"
import { ToolPanel, ToolPanelNav } from "./ToolPanel"
import { AutomatedSearchPanel } from "./auto_search_panel/AutomatedSearchPanel"
import { AutomatedSearchPanelNav } from "./auto_search_panel/AutomatedSearchPanelNav"
import {PubMedIcon} from "../../PubMedIcon"
// ### PortalLegacy ### \\
//import { PortalPanel, PortalPanelNav } from "../panels/PortalPanel"
// ### PortalLegacy ### \\
import { DashboardMenu } from "../DashboardMenu"
import {
	clearSelectedArticles,
	selectSelectedArticles,
} from "../../reducers/selectedArticlesSlice"
import MultiSelect from "./MultiSelect"
import {selectAllUnassignedArticleIds} from "../../reducers/unassignedArticlesEventsSlice"
import FormattedToolTip from "../../utils/FormattedToolTip"
import NotificationPanel from "./Notifications/NotificationPanel"
import NotificationPanelNav from "./Notifications/NotificationPanelNav"
import { fetchUserNotifications } from "../../reducers/notificationsSlice"
import { selectUnreadNotifications } from "../../reducers/notificationsSlice"

import {DYNAMIC_PANEL_TAB_INDEX_MAP, selectDynamicView, setDynamicView} from "../../reducers/dynamicViewSlice"
import CitationImportNav from "./CitationImport/CitationImportNav"
import CitationImportPanel from "./CitationImport/CitationImportPanel"
//import {selectNoteArticleTabsV2} from "../../reducers/noteArticleTabsV2Slice"


function DynamicPanelNav({
	openTab,
	workspace,
	setOpenWorkspaceManager,
	setOpenSupportModal,
	setOpenWorkspacePermissions
}) {
	const articleNoteList = useSelector(selectNoteArticleTabs)
	//const articleNoteListV2 = useSelector(selectNoteArticleTabsV2)
	const notifications = useSelector(selectUnreadNotifications)
	const dispatch = useDispatch()
	const handleChangeTab = (event, newValue) => {
		dispatch(setDynamicView(newValue))
	}


	useEffect(() => {
		// Navigates note articles list tabs when new article list is opened
		if (articleNoteList.tabs.length) {
			dispatch(setDynamicView(DYNAMIC_PANEL_TAB_INDEX_MAP.NOTE_ARTICLE_LIST))
		}
	}, [articleNoteList.currentIndex])

	return (
		<NavigationWrapper>
			<NavigationTabWrapper>
				<NavigationTabs
					value={openTab}
					onChange={handleChangeTab}
					aria-label="dynamic-tabs"
				>
					<Tooltip
						slotProps={{
							popper: {
								modifiers: [
									{
										name: "offset",
										options: {
											offset: [0, 30],
										},
									},
								],
							},
						}}
						disableInteractive
						title={
							<FormattedToolTip
								title={"Search Once"}
								description={"For Articles in PubMed or my Workspace."}
							/>
						}>
						<NavigationTab icon={<Search />} aria-label="article-searching" />
					</Tooltip>
					<Tooltip
						slotProps={{
							popper: {
								modifiers: [
									{
										name: "offset",
										options: {
											offset: [0, 30],
										},
									},
								],
							},
						}}
						disableInteractive
						title={
							<FormattedToolTip
								title={"Articles"}
								description={"Attached to a note."}
							/>
						}>
						<NavigationTab icon={<FileOpen />} aria-label="note-articles" />
					</Tooltip>
					<Tooltip
						slotProps={{
							popper: {
								modifiers: [
									{
										name: "offset",
										options: {
											offset: [0, 30],
										},
									},
								],
							},
						}}
						disableInteractive
						title={
							<FormattedToolTip
								title={"Tools"}
								description={"Your recently viewed and excluded articles."}
							/>
						}>
						<NavigationTab icon={<Build />} aria-label="tools" />
					</Tooltip>
					<Tooltip
						slotProps={{
							popper: {
								modifiers: [
									{
										name: "offset",
										options: {
											offset: [0, 30],
										},
									},
								],
							},
						}}
						disableInteractive
						title={
							<>
								<FormattedToolTip
									title={"Automated Searches to PubMed"}
								/>
							</>
						}>
						<NavigationTab icon={<SvgIcon><PubMedIcon /></SvgIcon>} aria-label="automated-search" />
					</Tooltip>
					<Tooltip
						slotProps={{
							popper: {
								modifiers: [
									{
										name: "offset",
										options: {
											offset: [0, 30],
										},
									},
								],
							},
						}}
						disableInteractive
						title={
							<>
								<FormattedToolTip
									title={"Notifications"}
									description={"View shared articles and other notifications."}
								/>
							</>
						}>
						<NavigationTab icon={
							<>
								{
									notifications.length > 0 && (
										<div style={{position: "relative", overflow: "visible"}}>
											<div style={{position: "absolute", bottom: "0px", left: "16px", paddingLeft: "4px", paddingRight: "4px",  paddingTop: "1px", paddingBottom: "1px", backgroundColor: "red", borderRadius: "4px"}}>
												<div style={{fontSize: "0.75rem", color: "white"}}>{notifications.length}</div>
											</div>
											<Notifications />
										</div>
									)
								}
								{
									notifications.length < 1 && (
										<Notifications />
									)
								}
							</>


						} aria-label="notifications" />
					</Tooltip>
					<Tooltip
						slotProps={{
							popper: {
								modifiers: [
									{
										name: "offset",
										options: {
											offset: [0, 30],
										},
									},
								],
							},
						}}
						disableInteractive
						title={
							<>
								<FormattedToolTip
									title={"Import Citations"}
									description={"Import specific citations by ID."}
								/>
							</>
						}>
						<NavigationTab icon={<CloudUpload />} aria-label="citation-import" />
					</Tooltip>
				</NavigationTabs>
				<span style={{ flexGrow: 1 }} />
				<DashboardMenu
					setOpenWorkspaceManager={setOpenWorkspaceManager}
					setOpenSupportModal={setOpenSupportModal}
					setOpenWorkspacePermissions={setOpenWorkspacePermissions}
				/>
			</NavigationTabWrapper>
			<Box sx={{ background: "white" }}>
				<NavigationSecondaryFnWrapper>
					<NavPanel
						value={openTab}
						index={DYNAMIC_PANEL_TAB_INDEX_MAP.ARTICLE_SEARCH}
					>
						<SearchPanelNav workspace={workspace} />
					</NavPanel>
					<NavPanel
						value={openTab}
						index={DYNAMIC_PANEL_TAB_INDEX_MAP.NOTE_ARTICLE_LIST}
					>
						<NoteArticleListPanelNav />
					</NavPanel>
					<NavPanel value={openTab} index={DYNAMIC_PANEL_TAB_INDEX_MAP.TOOLS}>
						<ToolPanelNav />
					</NavPanel>
					<NavPanel
						value={openTab}
						index={DYNAMIC_PANEL_TAB_INDEX_MAP.AUTOMATED_SEARCH}
					>
						<AutomatedSearchPanelNav />
					</NavPanel>
					<NavPanel value={openTab} index={DYNAMIC_PANEL_TAB_INDEX_MAP.NOTIFICATION}>
						<NotificationPanelNav />
					</NavPanel>
					<NavPanel value={openTab} index={DYNAMIC_PANEL_TAB_INDEX_MAP.NON_PUBMED_IMPORT}>
						<CitationImportNav />
					</NavPanel>

				</NavigationSecondaryFnWrapper>
			</Box>
		</NavigationWrapper>
	)
}

export default function DynamicPanel({
	workspace,
	setOpenWorkspaceManager,
	setOpenSupportModal,
	setOpenWorkspacePermissions
}) {
	console.log("Attach Link - Dynamic Panel")
	const dispatch = useDispatch()
	const selectedArticles = useSelector(selectSelectedArticles)
	const articleNoteLists = useSelector(selectNoteArticleTabs)
	const unassignedArticleIds = useSelector(selectAllUnassignedArticleIds)
	const openTab = useSelector(selectDynamicView)

	const [showMultiSelect, setShowMultiSelect] = useState(false)

	useEffect(() => {
		dispatch(fetchUserNotifications())
	}, [dispatch])


	useEffect(() => {
		Object.keys(selectedArticles).length > 0
			? setShowMultiSelect(true)
			: setShowMultiSelect(false)
	}, [selectedArticles])

	useEffect(() => {
		dispatch(clearSelectedArticles(selectedArticles)) //clear multiselect when tab changes
	}, [openTab])

	return (
		<Box id="panel-container" sx={{backgroundColor: "primary.rOneBG"}}>
			<DynamicPanelNav
				openTab={openTab}
				workspace={workspace}
				setOpenWorkspaceManager={setOpenWorkspaceManager}
				setOpenSupportModal={setOpenSupportModal}
				setOpenWorkspacePermissions={setOpenWorkspacePermissions}
			/>
			<TabPanel
				value={openTab}
				index={DYNAMIC_PANEL_TAB_INDEX_MAP.ARTICLE_SEARCH}
			>
				<SearchPanel workspace={workspace} unassignedArticleIds={unassignedArticleIds}/>
			</TabPanel>
			<TabPanel
				value={openTab}
				index={DYNAMIC_PANEL_TAB_INDEX_MAP.NOTE_ARTICLE_LIST}
			>
				<NoteArticleListPanel workspace={workspace} />
			</TabPanel>
			<TabPanel value={openTab} index={DYNAMIC_PANEL_TAB_INDEX_MAP.TOOLS}>
				<ToolPanel workspace={workspace} />
			</TabPanel>
			<TabPanel
				value={openTab}
				index={DYNAMIC_PANEL_TAB_INDEX_MAP.AUTOMATED_SEARCH}
			>
				<AutomatedSearchPanel workspace={workspace} unassignedArticleIds={unassignedArticleIds}/>
			</TabPanel>
			<TabPanel value={openTab} index={DYNAMIC_PANEL_TAB_INDEX_MAP.NOTIFICATION}>
				<NotificationPanel/>
			</TabPanel>
			<TabPanel value={openTab} index={DYNAMIC_PANEL_TAB_INDEX_MAP.NON_PUBMED_IMPORT}>
				<CitationImportPanel />
			</TabPanel>
			<MultiSelect
				open={showMultiSelect}
				selectedArticles={selectedArticles}
				workspace={workspace}
				articleNoteLists={articleNoteLists}
				openTab={openTab}
			/>
		</Box>
	)
}
