import {createAsyncThunk, createSelector, createSlice,
	// current
} from "@reduxjs/toolkit"
import {
	// moveNote,
	addNote, addNoteToTree, deleteNote, deleteNoteFromTree,
	// workspaceNoteSlice,
	fetchTopLevelNotes, moveNote, moveNoteInTree
} from "./workspaceNoteSlice"
import { undoDeleteNote } from "./undoDeleteNoteSlice"
import {setWorkspace} from "./workspaceSlice"
import {selectAllowedNoteIds, selectIsLoading} from "./noteRestrictionsSlice"


export const setLoadedChildNotesOrder = createAsyncThunk(
	"noteOrder/setLoadedChildNotesOrder",
	async (flatNotes) => {
		const orderArray = new Array(Object.keys(flatNotes).length)
		let parentId
		for(let flatNoteIndex in flatNotes) {
			const flatNote = flatNotes[flatNoteIndex]
			if(!parentId){
				parentId = flatNote.parentId
			}
			orderArray[flatNote.order] = flatNote.id
		}
		return {parentId, orderArray}
	}
)

export const clearNotesOrder = createAsyncThunk(
	"noteOrder/clearNotesOrder",
	async (_, { getState }) => {
		const state = getState()
		const existingRoot = state?.noteOrder?.root
		return existingRoot
	}
)

function insertBeforeTarget(arr, newInt, targetInt) {
	const targetIndex = arr.indexOf(targetInt)

	if (targetIndex === -1) {
		throw new Error("Target integer not found in the array")
	}

	// Create a new array with the new integer inserted before the target integer
	return [...arr.slice(0, targetIndex), newInt, ...arr.slice(targetIndex)]
}

const initialState = { root: [] }
export const noteOrderSlice = createSlice({
	name: "noteOrder",
	initialState,
	reducers: {
		setRootOrder: (state, action) => {
			let newRoot = new Array(Object.keys(action.payload).length)
			for(let flatNoteIndex in action.payload) {
				const flatNote = action.payload[flatNoteIndex]
				const index = flatNote.order
				const id = flatNote.id

				newRoot[index] = id
			}
			state.root = newRoot
		},
		pushNoteToOrder: (state, action) => {
			const {note, targetNote, pasteAsSibling} = action.payload
			if (note.parentId) {
				const originalArray = state[note.parentId] ? state[note.parentId] : []
				state[note.parentId] = pasteAsSibling
					? insertBeforeTarget(originalArray, note.id, targetNote.id)
					: [note.id, ...originalArray]
			} else {
				const originalArray = state.root
				state.root = insertBeforeTarget(originalArray, note.id, targetNote.id)
			}
		},
		setChildOrder: (state, action) => {
			const {parentId, orderArray} = action.payload
			state[parentId] = orderArray
		},
		resetOrderState: (state, action) => initialState, // eslint-disable-line no-unused-vars,
	},
	extraReducers: (builder) => {
		builder.addCase(
			undoDeleteNote.fulfilled,
			(state, action) => {
				const { deletedNote } = action.payload
				if (deletedNote.path.split(".").length === 1) {
					// create new root array with deleted note in at index 0
					const newRootArray = [deletedNote.id, ...state.root]
					state.root = newRootArray
				} else {
					// create tmp array for parent order array with deleted note at index 0
					let tmp = state[deletedNote.parentId] ? state[deletedNote.parentId] : []
					tmp = [deletedNote.id, ...tmp]
					state[deletedNote.parentId] = tmp
				}
			}
		)
		builder.addCase(
			fetchTopLevelNotes.fulfilled,
			noteOrderSlice.caseReducers.setRootOrder
		)
		builder.addCase(
			setLoadedChildNotesOrder.fulfilled,
			noteOrderSlice.caseReducers.setChildOrder
		)
		builder.addCase(addNote.fulfilled, (state, action) => {
			const { newNote, addingSiblingNote, invokingNote, incomingChildNotes} = action.payload
			if(addingSiblingNote) {													// check if we're adding a sibling
				const invokingNoteIndex = state[newNote.parentId].findIndex(noteId => noteId === invokingNote.id)
				state[newNote.parentId].splice(invokingNoteIndex, 0, newNote.id)		// if adding a sibling, the parent must be in the order slice, so we just splice in the id of the new note
			} else {
				const newNoteisRoot = newNote.parentId === null							// check if we're adding a top-level note
				if(newNoteisRoot) {
					console.log("handling fulfilled root note insertion, what is action payload: ", action.payload)
					// because we don't have a parent ID to reference or an order value,
					// we have to look at the invoking note to know where to insert the new top-level note
					let siblingRootIndex = state.root.findIndex(noteId => noteId === invokingNote.id)
					console.log("did we find sibling rootIndex: ", siblingRootIndex)
					state["root"].splice(siblingRootIndex, 0, newNote.id)			// state.root is hardcoded in and will always exist
				} else {
					const noteIdInState = state[newNote.parentId] 					// see if the parent note is in the order state
					if(noteIdInState) {
						state[newNote.parentId].unshift(newNote.id) 				// if the parent is in the order slice, its a simple splice
					} else {
						let childrenNoteIds = incomingChildNotes.map(note => note.id)// if the note isn't in the order slice, create an array from the ids of its children
						childrenNoteIds.unshift(newNote.id) 						 // new child note goes to the top of the children list
						state[newNote.parentId] = childrenNoteIds
					}
				}
			}
		})
		builder.addCase(clearNotesOrder.fulfilled, (state, action) => {
			// action.payload will contain a copy of the existing root map
			return {root: action.payload}
		})
		builder.addCase(
			setWorkspace,
			noteOrderSlice.caseReducers.resetOrderState
		)
		builder.addCase(moveNote.fulfilled, (state, action) => {
			// deconstruct required payload values, set up constants based on the current map's values
			const {newNote, originalNote, incomingChildNotes, originalNoteLocation, invokingNote} = action.payload
			const isMovingFromRoot = originalNoteLocation.parentId === null
			const isMovingToRoot = newNote.parentId === null
			//remove the original note.id from its parent's map in state
			const originOrderArray = state[isMovingFromRoot ? "root" : originalNoteLocation.parentId]
			const originalNoteIdIndex = originOrderArray.findIndex(noteId => noteId === originalNote.id)
			if(originalNoteIdIndex > -1) {
				state[isMovingFromRoot ? "root" : originalNoteLocation.parentId].splice(originalNoteIdIndex, 1)
			}
			//attempt to find an existing map of children note IDs for the destination note currently in the state
			const destinationOrderArray = state[isMovingToRoot ? "root" : newNote.parentId]
			if(destinationOrderArray) {							// if current state has a map of children for the destination note...
				if(invokingNote){								// if the action.payload includes an invoking note, then we're responding to a local move, not a networked move
					let invokingNoteIndex = state[isMovingToRoot ? "root" : newNote.parentId].findIndex(noteId => noteId === invokingNote.id) 			// find the invoking note index
					if(invokingNoteIndex === -1) {
						invokingNoteIndex = 0 // if we can't locate the invoking note (often happens handling moves in networked workspaces), insert at index 0
					}
					destinationOrderArray.splice(invokingNoteIndex, 0, newNote.id)												// splice in the new note at the invoking note index
				} else {
					destinationOrderArray.unshift(newNote.id)			// insert the moved note at index 0 of the existing map
				}
				state[isMovingToRoot ? "root" : newNote.parentId] = destinationOrderArray			// mutate the state!
			} else {											// if the current state doesn't have a map of children for the destination note
				if(incomingChildNotes) {							// ...but the destination has other children
					let childrenNoteIds = incomingChildNotes.map(note => note.id)	// make a map from the ids of the incoming child notes
					childrenNoteIds.unshift(newNote.id)								// insert the moving note ID at index 0 of the map
					state[newNote.parentId] = childrenNoteIds						// set the value in the state to the new map.
				} else {											// the moving note is the first child
					state[newNote.parentId] = [newNote.id]							// make a new map for the parent and assign it to the state
				}
			}
		})
		builder.addCase(moveNoteInTree, (state, action) => {
			// deconstruct required payload values, set up constants based on the current map's values
			const {newNote, originalNote, incomingChildNotes, originalNoteLocation, invokingNote} = action.payload
			const isMovingFromRoot = originalNoteLocation.parentId === null
			const isMovingToRoot = newNote.parentId === null
			//remove the original note.id from its parent's map in state
			const originOrderArray = state[isMovingFromRoot ? "root" : originalNoteLocation.parentId]
			if(originOrderArray) {
				const originalNoteIdIndex = originOrderArray.findIndex(noteId => noteId === originalNote.id)
				if(originalNoteIdIndex > -1) {
					state[isMovingFromRoot ? "root" : originalNoteLocation.parentId].splice(originalNoteIdIndex, 1)
				}
			}
			//attempt to find an existing map of children note IDs for the destination note currently in the state
			const destinationOrderArray = state[isMovingToRoot ? "root" : newNote.parentId]
			if(destinationOrderArray) {							// if current state has a map of children for the destination note...
				if(invokingNote){								// if the action.payload includes an invoking note, then we're responding to a local move, not a networked move
					const invokingNoteIndex = state[newNote.parentId].findIndex(noteId => noteId === invokingNote.id) 			// find the invoking note index
					destinationOrderArray.splice(invokingNoteIndex, 0, newNote.id)												// splice in the new note at the invoking note index
				} else {
					destinationOrderArray.unshift(newNote.id)			// insert the moved note at index 0 of the existing map
				}
				state[newNote.parentId] = destinationOrderArray			// mutate the state!
			} else {											// if the current state doesn't have a map of children for the destination note
				if(incomingChildNotes) {							// ...but the destination has other children
					let childrenNoteIds = incomingChildNotes.map(note => note.id)	// make a map from the ids of the incoming child notes
					childrenNoteIds.unshift(newNote.id)								// insert the moving note ID at index 0 of the map
					state[newNote.parentId] = childrenNoteIds						// set the value in the state to the new map.
				} else {											// the moving note is the first child
					state[newNote.parentId] = [newNote.id]							// make a new map for the parent and assign it to the state
				}
			}
		})
		builder.addCase(deleteNote.fulfilled, (state, action) => {
			const {partialDeletedNoteNfo} = action.payload
			const {noteId: deletedNoteId, parentNoteId} = partialDeletedNoteNfo
			const parentNoteMap = state[parentNoteId]
			const deletedNoteIndex = parentNoteMap.findIndex(noteId => noteId === deletedNoteId)
			state[parentNoteId].splice(deletedNoteIndex, 1)
		})
		builder.addCase(addNoteToTree, (state, action) => {
			console.log("handling addNoteToTree")
			const newNote = action.payload
			const newNoteIsRoot = newNote.parentId === null
			//TODO: ask DK about what to do here... I don't think it makes sense to insert these at the position of the other client's input
			if(newNoteIsRoot) {
				state["root"].push(newNote.id)
			} else {
				const newNoteParentInState = state[newNote.parentId]
				if(newNoteParentInState) {
					state[newNote.parentId].unshift(newNote.id)
				}
				// else, if the parent isn't in the order map, it doesn't matter because the client will load in the children when the user opens the parent
			}
		})
		builder.addCase(deleteNoteFromTree, (state, action) => {
			const {parentId, id: noteId} = action.payload
			const parentNoteMap = state[parentId !== null ? parentId : "root"]
			if(parentNoteMap) {
				const deletedNoteIndex = parentNoteMap.findIndex(mapNoteId => mapNoteId === noteId)
				state[parentId !== null ? parentId : "root"].splice(deletedNoteIndex, 1)
			}
			// else, if the parent isn't in the order map, it doesn't matter because the client will load in the children when the user opens the parent
		})
	},
})

export const { setRootOrder, setChildOrder, resetOrderState, pushNoteToOrder } = noteOrderSlice.actions

export const selectAllNoteOrders = (state) => state.noteOrder

export const selectNotesOrder = createSelector(
	//parentId is a parameter passed in when this selector is invoked, we fake it as an existing selector for Redux Toolkit
	[selectAllNoteOrders, selectAllowedNoteIds, selectIsLoading, (state, parentId) => parentId],
	(noteOrders, notePermissions, notePermissionsLoading, parentId) => {
		if(notePermissionsLoading) {
			return []
		} else {
			const hasFullAccess = notePermissions.length === 0
			if(parentId){
				if(parentId === "root" && !hasFullAccess){
					return noteOrders["root"].filter(noteId => notePermissions.includes(noteId))
				} else {
					return noteOrders[parentId]
				}
			} else {
				console.log("doesn't have parentId value")
				const rootOrders = noteOrders["root"]
				const filteredRootOrders = rootOrders.filter(noteId => notePermissions.includes(noteId))
				return filteredRootOrders.length && !hasFullAccess ? filteredRootOrders : filteredRootOrders.length ? rootOrders : []
			}
		}
	}
)

export default noteOrderSlice.reducer
