import {
	Menu,
	Item,
	Separator,
} from "react-contexify"
import {useDispatch, useSelector} from "react-redux"
import {clearNoteAction, selectNoteAction, setNoteAction, NOTE_ACTIONS} from "../../../reducers/noteActionSlice"
import React, { useState } from "react"
import DeleteNoteModal from "./DeleteNoteModal"
import { initializeMovingNote, cancelMovingNote } from "../../../reducers/moveNoteSlice"
import { moveNote, pasteCopy } from "../../../reducers/workspaceNoteSlice"

import {
	Add,
	Close,
	DoDisturb,
	Edit,
	KeyboardDoubleArrowDown,
	KeyboardDoubleArrowLeft,
	ZoomOutMap,
	AddLink,
	CopyAll
} from "@mui/icons-material"
import { useSnackbar } from "notistack"
import { initializeLinkAttachmentModal } from "../../../reducers/noteAttachmentsSlice"
import NoteAttachmentModal from "./NoteAttachmentModal"
import {selectTargetNote} from "../../../reducers/targetNoteSlice"
import { cancelCopyNote, initializeCopyNote } from "../../../reducers/copyNoteSlice"
import { selectCopyNote } from "../../../reducers/copyNoteSlice"

const NoteMenu =  React.memo(function NoteMenu({
	permissions,
	setShowingContext,
	hideAll
}) {
	console.log("Attach Link - Note Menu")
	const dispatch = useDispatch()
	const noteAction = useSelector(selectNoteAction)
	const copyNote = useSelector(selectCopyNote)
	const { enqueueSnackbar } = useSnackbar()
	const targetNote = useSelector(selectTargetNote)
	const [movingNote, setMovingNote] = useState(null)
	const [ isDeleteModalOpen, setIsDeleteModalOpen ] = useState(null)
	const [ workspaceId, setWorkspaceId ] = useState(null)
	const [ noteToDelete, setNoteToDelete ] = useState(null)

	const checkIfNoteCanMove = (noteBeingMoved, receivingNote) => {
		const noteIsSelf = receivingNote.id === noteBeingMoved.id
		const receivingPath = receivingNote.path.split(".")
		const parentIsChild = receivingPath.includes(`${noteBeingMoved.id}`)

		if (noteIsSelf) {
			enqueueSnackbar("Cannot move a note to itself.", { variant: "info" })
		} else if (parentIsChild) {
			enqueueSnackbar("Cannot move a parent note inside one of its children.", {
				variant: "info",
			})
		}

		return !noteIsSelf && !parentIsChild
	}

	const handleMouseLeave = (e) => {
		const mouseLeavingToMenuIcon =
			e.relatedTarget.classList.contains("MuiSvgIcon-root") // related target is SVG
			|| e.relatedTarget.tagName === "path" // related target is child of SVG
		if(!mouseLeavingToMenuIcon){
			hideAll()
		}
	}

	const handleItemClick = async ({ data, props }) => {
		hideAll()
		switch (data) {
			case "add-child":
				dispatch(setNoteAction({ noteId: targetNote.id, action: NOTE_ACTIONS.addChild }))
				break
			case "add-sibling":
				if (targetNote.parentId) {
					dispatch(
						setNoteAction({ noteId: targetNote.id, action: NOTE_ACTIONS.addSibling })
					)
				} else {
					dispatch(setNoteAction({ noteId: targetNote.id, action: NOTE_ACTIONS.addRoot }))
				}
				break
			case "edit":
				dispatch(setNoteAction({ noteId: targetNote.id, action: NOTE_ACTIONS.editNote }))
				break
			case "move":
				dispatch(initializeMovingNote(targetNote))
				setMovingNote(targetNote)
				break
			case "move-as-child":
				if (checkIfNoteCanMove(movingNote, targetNote)) {
					dispatch(moveNote({movingNote, receivingNoteId: targetNote.id, receivingPath: targetNote.path}))
					setMovingNote(null)
				}
				break
			case "paste-as-child":
				console.log("paste as child")
				dispatch(pasteCopy({
					pasteAsSibling: false,
					noteToPaste: copyNote.value,
					targetNote,
				}))
				break
			case "move-as-sibling":
				if (checkIfNoteCanMove(movingNote, targetNote)) {
					dispatch(moveNote({movingNote, receivingNoteId: targetNote.parentId, receivingPath: targetNote.path}))
					setMovingNote(null)
				}
				break
			case "paste-as-sibling":
				dispatch(pasteCopy({
					pasteAsSibling: true,
					noteToPaste: copyNote.value,
					targetNote,
				}))
				break
			case "cancel-move":
				dispatch(cancelMovingNote(movingNote)) 
				setMovingNote(null)
				break
			case "cancel-copy":
				dispatch(cancelCopyNote())
				break
			case "cancel-edit":
				dispatch(clearNoteAction())
				break
			case "delete":
				setIsDeleteModalOpen(true)
				setWorkspaceId(props.workspaceId)
				setNoteToDelete(targetNote.id)
				break
			case "attach-link":
				// select note
				// note select extraReducer opens modal
				dispatch(initializeLinkAttachmentModal({note: targetNote, actionType: "create"}))
				break
			case "copy":
				dispatch(initializeCopyNote(targetNote))
				break
			default:
				break
		}
	}

	if (!permissions.write) {
		return (
			<Menu
				id={"note_menu"}
				onVisibilityChange={setShowingContext}
				animation={{
					enter: "fade",
					exit: false
				}}
				onMouseLeave={() => hideAll()}
			>
				<Item>You Do Not Have Permissions</Item>
			</Menu>
		)
	}

	if( [NOTE_ACTIONS.editNote, NOTE_ACTIONS.addChild, NOTE_ACTIONS.addSibling, NOTE_ACTIONS.addRoot].includes(noteAction.action) ) {
		return null
	}

	if (copyNote.value.id > 1) {
		return (
			<Menu
				id={"note_menu"}
				onVisibilityChange={setShowingContext}
				animation={{
					enter: "fade",
					exit: false
				}}
				onMouseLeave={handleMouseLeave}
			>
				<Item data="paste-as-child" onClick={handleItemClick}>
					<KeyboardDoubleArrowDown
						fontSize={"small"}
						color={"primary"}
						sx={{ paddingRight: "5px" }}
					/>
					Paste as child
				</Item>
				<Item data="paste-as-sibling" onClick={handleItemClick}>
					<KeyboardDoubleArrowLeft
						fontSize={"small"}
						sx={{ color: "#33C73B", paddingRight: "5px" }}
					/>
					Paste as Sibling
				</Item>
				<Separator />
				<Item data="cancel-copy" onClick={handleItemClick}>
					<DoDisturb
						fontSize={"small"}
						sx={{ color: "#C72D30", paddingRight: "5px" }}
					/>
					Cancel
				</Item>
			</Menu>
		)
	}

	if (movingNote) {
		return (
			<Menu
				id={"note_menu"}
				onVisibilityChange={setShowingContext}
				animation={{
					enter: "fade",
					exit: false
				}}
				onMouseLeave={handleMouseLeave}
			>
				<Item data="move-as-child" onClick={handleItemClick}>
					<KeyboardDoubleArrowDown
						fontSize={"small"}
						color={"primary"}
						sx={{ paddingRight: "5px" }}
					/>
					Paste as child
				</Item>
				<Item data="move-as-sibling" onClick={handleItemClick}>
					<KeyboardDoubleArrowLeft
						fontSize={"small"}
						sx={{ color: "#33C73B", paddingRight: "5px" }}
					/>
					Paste as Sibling
				</Item>
				<Separator />
				<Item data="cancel-move" onClick={handleItemClick}>
					<DoDisturb
						fontSize={"small"}
						sx={{ color: "#C72D30", paddingRight: "5px" }}
					/>
					Cancel
				</Item>
			</Menu>
		)
	}

	return (
		<>
			<Menu
				id={"note_menu"}
				animation={{
					enter: "fade",
					exit: false
				}}
				onMouseLeave={handleMouseLeave}
				onVisibilityChange={setShowingContext}
				style={{ minWidth: "150px" }}
			>
				<Item data="add-child" onClick={handleItemClick}>
					<Add
						fontSize={"small"}
						sx={{ color: "#33C73B", paddingRight: "5px" }}
					/>
					Add child
				</Item>
				<Item data="add-sibling" onClick={handleItemClick}>
					<Add
						fontSize={"small"}
						sx={{ color: "#33C73B", paddingRight: "5px" }}
					/>
					Add sibling
				</Item>
				<Separator />
				<Item data="edit" onClick={handleItemClick}>
					<Edit
						fontSize={"small"}
						sx={{ color: "#c78f33", paddingRight: "5px" }}
					/>
					Edit note
				</Item>
				<Item data="move" onClick={handleItemClick}>
					<ZoomOutMap
						fontSize={"small"}
						sx={{ color: "#6985cb", paddingRight: "5px" }}
					/>
					Move note
				</Item>
				<Item data="copy" onClick={handleItemClick}>
					<CopyAll 
						fontSize="small"
						sx={{ color: "#6985cb", paddingRight: "5px" }}
					/>
					Copy notes
				</Item>
				<Separator />
				<Item data={"attach-link"} onClick={handleItemClick}>

					<AddLink
						fontSize={"small"}
						sx={{ color: "#656565", paddingRight: "5px" }}
					/>
					Attach Link
				</Item>
				<Separator />
				<Item data="delete" onClick={handleItemClick}>
					<Close
						fontSize={"small"}
						sx={{ color: "#C72D30", paddingRight: "5px" }}
					/>
					Delete note
				</Item>
			</Menu>
			<Menu id={"portal_menu"}>
				<Item data={"copy-portal-note"} onClick={handleItemClick}>
					Export to Workspace
				</Item>
			</Menu>
			<NoteAttachmentModal/>
			<DeleteNoteModal
				open={isDeleteModalOpen}
				setIsOpen={setIsDeleteModalOpen}
				workspaceId={workspaceId}
				noteId={noteToDelete}>
			</DeleteNoteModal>
		</>
	)
})

export default NoteMenu
